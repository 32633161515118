// Add additional styles here. For example, overwrite certain styles or add new components.
// Tip: You can use bootstrap's powerful mixins here!

//.alert-myalert {
//  @include alert-variant(#60667d, #1d1d1d, #f4fdff);
//}

//@each $color, $value in $theme-colors {
//  .alert-#{$color} {
//    box-shadow: 3px 3px theme-color-level($color, -3);
//  }
//}
p a {
    text-decoration: underline;
}
